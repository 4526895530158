
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonItem,
  IonIcon,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonLabel,
  IonTitle,
  IonChip,
  IonInput,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline, addOutline, closeOutline } from "ionicons/icons";
import { myProfile } from "@/components/profile/index";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Hobbies",
  components: {
    IonButton,
    IonItem,
    IonIcon,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonLabel,
    IonTitle,
    IonChip,
    IonInput,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const myHobbie = ref<string>("");
    const addCustomHobbie = () => {
      if (myHobbie.value.trim() == "") {
        return;
      }

      const newHobbie = myHobbie.value?.trim();

      if (!myProfile.value?.customHobbies) {
        if (myProfile.value != null) {
          myProfile.value["customHobbies"] = new Set([newHobbie]);
        }
        return;
      }

      myProfile.value?.customHobbies?.add(newHobbie);
      myHobbie.value = "";
    };

    const removeCustomHobbie = (item: string) => {
      if (myProfile.value?.customHobbies?.has(item)) {
        myProfile.value?.customHobbies?.delete(item);
      }
    };

    const filteredHobbiesList = ref(jsonData.hobbies);
    filteredHobbiesList.value.forEach((item) => {
      if (myProfile.value?.hobbies) {
        if (myProfile.value?.hobbies.has(item.code)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    const hobbyToggle = (selectedHobby: any) => {
      if (!myProfile.value?.hobbies) {
        // myProfile.value = {...myProfile.value, hobbies:new Set()};
        if (myProfile.value != null) {
          selectedHobby.isChecked = true;
          myProfile.value["hobbies"] = new Set([selectedHobby.code]);
        }
        return;
      }

      if (myProfile.value?.hobbies.has(selectedHobby.code)) {
        selectedHobby.isChecked = false;
        myProfile.value?.hobbies.delete(selectedHobby.code);
      } else {
        selectedHobby.isChecked = true;
        myProfile.value?.hobbies.add(selectedHobby.code);
      }
    };

    return {
      addCustomHobbie,
      removeCustomHobbie,
      myHobbie,
      addOutline,
      closeOutline,
      hobbyToggle,
      filteredHobbiesList,
      myProfile,
      closeModal,
      checkmarkOutline,
    };
  },
});
