
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  IonCheckbox,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
// import { myCriteria } from "@/composables/partnerCriteria";
import {myProfile} from "@/components/profile/index";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Languages",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
    IonCheckbox,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };
    
    const languagesList = ref(jsonData.languages);
    
    languagesList.value.forEach((item) => {
      if (myProfile.value?.languages) {
        if (myProfile.value?.languages.has(item.code)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    /** Add or remove language to/from criteria directory. */
    const checkboxChanged = (event: any) => {
      // console.log(event);
      const langCode: string = event.detail.value;
      if (!myProfile.value?.languages) {
        if (myProfile.value != null) {
          myProfile.value["languages"] = new Set([langCode]);
        }
        return;
      }

      if (event.detail.checked == false) {
        myProfile.value.languages.delete(langCode);
      } else if (event.detail.checked == true) {
        myProfile.value.languages.add(langCode);
      }
    };

    return {
      checkboxChanged,
      languagesList,
      closeModal,
      checkmarkOutline,
    };
  },
});
