<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{ $t("buttons.done") }}
          </ion-button>
        </ion-buttons>
        <ion-title>
          <img src="/assets/logo.png" style="height: 30px" />
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card
        style="
          overflow-y: scroll;
          margin: 0px;
          border-radius: 0px;
          box-shadow: none;
          height: 100%;
        "
      >
        <ion-card-header id="header">
          <ion-card-subtitle>{{ $t("profile.work.work") }}</ion-card-subtitle>
          <ion-card-title>{{ $t("profile.work.question") }}</ion-card-title>
          <div style="padding: 0px">
            <ion-searchbar
              v-model="searchQuery"
              :placeholder="$t('buttons.search')"
              style="padding-left: 0px; padding-right: 0px"
            ></ion-searchbar>
          </div>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-radio-group v-model="myProfile.baseInfo.mainInfo.work">
              <ion-item
                v-for="item in filteredJobsList"
                :key="item.code"
               
              >
                <ion-label>{{
                  $t("profile.work.options." + item.code)
                }}</ion-label>
                <ion-radio
                  slot="start"
                  color="primary"
                  :value="item.code"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-card-content>
      </ion-card>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="scrollToTop()" size="small" color="light">
          <ion-icon :icon="arrowUpOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonRadio,
  IonRadioGroup,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  IonList,
  IonFab,
  IonIcon,
  IonFabButton,
  IonSearchbar,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline, arrowUpOutline } from "ionicons/icons";
import jsonData from "@/assets/criteriaData.json";
import { myProfile } from "@/components/profile/index";

export default defineComponent({
  name: "Work",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonIcon,
    IonLabel,
    IonList,
    IonFab,
    IonFabButton,
    IonTitle,
    IonSearchbar,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };
    const listOfJobs = ref(jsonData.jobs);

    const searchQuery = ref("");
    const lang = localStorage.language == "gb" ? "en" : "sk";
    const filteredJobsList = computed(() => {
      const filtered = listOfJobs.value.filter((item: any) => {
        return (
          item[lang].toLowerCase().indexOf(searchQuery.value.toLowerCase()) !==
          -1
        );
      });
      return filtered.sort((a: any, b: any) => {
        if (a[lang] < b[lang]) {
          return -1;
        }
        if (a[lang] > b[lang]) {
          return 1;
        }
        return 0;
      });
    });

    const scrollToTop = () => {
      document.querySelector("ion-card-header#header")?.scrollIntoView({behavior: "smooth"});
    };

    return {
      scrollToTop,
      myProfile,
      filteredJobsList,
      searchQuery,
      closeModal,
      arrowUpOutline,
      checkmarkOutline,
    };
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
</style>
