import { ref, reactive, onMounted, } from "vue";
import { fbDB, fbStorage } from "@/firebase";
import { registrationProcess, user } from "@/auth";
import { ProfileStructure } from './model';
import { myCriteria } from "@/composables/partnerCriteria";

export const myProfile = ref<ProfileStructure | null>(null);
export const loadingMyProfile = ref<boolean>(true);
export const validationOn = ref<boolean>(false);

export const photoFiles = reactive({
  titlePhoto: null,
  profilePhoto: null,
  myPhotos: [null, null, null, null, null, null],
});

export async function loadMyProfile() {
  // clear it first
  loadingMyProfile.value = true;
  const myProfileRef = fbDB.ref("users/" + user.value.uid + "/profile");
  try {
    const snapshot = await myProfileRef.once("value");
    if (snapshot.val() !== null) {
      myProfile.value = snapshot.val();
      if (myProfile.value) {
        myProfile.value.hobbies = new Set(snapshot.val().hobbies);
        myProfile.value.customHobbies = new Set(snapshot.val().customHobbies)
        myProfile.value.languages = new Set(snapshot.val().languages);
      }
    } else {
      myProfile.value = {
        baseInfo: {
          name: user.value.displayName,
          titlePhoto: "",
          profilePhoto: "",
          bio: "",
          mainInfo: {
            work: "",
            education: "",
            location: "",
          },
        },
        languages: new Set,
        hobbies: new Set,
        customHobbies: new Set,
        smoking: "",
        religion: "",
        photos: ["", "", "", "", "", ""],
      };
    }
    loadingMyProfile.value = false;
  } catch (err) {
    console.log("ERROR: loadMyProfile()");
    myProfile.value = null;
    loadingMyProfile.value = false;
  }
}

export function clearMyProfile() {
  myProfile.value = null;
  photoFiles.titlePhoto = null;
  photoFiles.profilePhoto = null;
  photoFiles.myPhotos = [null, null, null, null, null, null];
  validationOn.value = false;
}

export function validateProfileIsNok() {
  if (
    myProfile.value?.baseInfo.mainInfo.work &&
    myProfile.value?.baseInfo.mainInfo.education &&
    myProfile.value?.baseInfo.mainInfo.location
  ) {
    return false;
  } else {
    return true;
  }
}

const uploadTitlePhoto = async (photo: File | null) => {
  if (myProfile.value == null) { return true; }
  if (photo != null) {
    try {
      const snapshot = await fbStorage
        .ref("/users/" + user.value.uid + "/titlePhoto")
        .put(photo);
      const photoUrl = await snapshot.ref.getDownloadURL();
      myProfile.value.baseInfo.titlePhoto = photoUrl;
      photoFiles.titlePhoto = null;
      return true;
    } catch (error) {
      console.log("ERROR uploadTitlePhoto()");
      console.log(error);
      myProfile.value.baseInfo.titlePhoto = "";
      return false;
    }
  } else {
    return true;
  }
};

const uploadProfilePhoto = async (photo: File | null) => {
  if (myProfile.value == null) { return true; }
  if (photo != null) {
    try {
      const snapshot = await fbStorage
        .ref("/users/" + user.value.uid + "/profilePhoto")
        .put(photo);
      const photoUrl = await snapshot.ref.getDownloadURL();
      myProfile.value.baseInfo.profilePhoto = photoUrl;
      photoFiles.profilePhoto = null;

      return true;
    } catch (error) {
      console.log("ERROR uploadProfilePhoto()");
      console.log(error);
      myProfile.value.baseInfo.profilePhoto = "";
      return false;
    }
  } else {
    return true;
  }
};

const uploadPhotoGallery = async (photo: any, index: number) => {
  if (myProfile.value == null) { return true; }
  if (photo != null) {
    try {
      const snapshot = await fbStorage
        .ref("/users/" + user.value.uid + "/photo" + index)
        .put(photo);
      const photoUrl = await snapshot.ref.getDownloadURL();
      if (myProfile.value.photos) {
        myProfile.value.photos[index] = photoUrl;
      }
      return true;
    } catch (error) {
      console.log("ERROR uploadPhotoGallery()");
      if (myProfile.value.photos) {
        myProfile.value.photos[index] = "";
      }
      return false;
    }
  } else {
    return true;
  }
};

export async function removePhotoFromStorage(photoCode: any) {
  try {
    await fbStorage.ref(`/users/${user.value.uid}/${photoCode}`).delete();
  } catch (err) {
    console.log(err);
  }
}

export async function saveMyProfile() {
  if (myProfile.value == null) {
    return { result: 'fail', error: 'database' };
  }

  let photoUploadSuccess;
  photoUploadSuccess = await uploadProfilePhoto(photoFiles.profilePhoto);
  photoUploadSuccess = await uploadTitlePhoto(photoFiles.titlePhoto);
  await Promise.all(
    photoFiles.myPhotos.map(async (photo, index) => {
      photoUploadSuccess = await uploadPhotoGallery(photo, index);
      photoFiles.myPhotos[index] = null;
    })
  );
  
  const data: any = { languages: [], hobbies: [], customHobbies: [] };
  myProfile.value.languages?.forEach((item) => {
    data.languages.push(item);
  });
  myProfile.value.hobbies?.forEach((item) => {
    data.hobbies.push(item);
  });
  myProfile.value.customHobbies?.forEach((item) => {
    data.customHobbies.push(item);
  });
  data.photos = myProfile.value.photos;
  data.baseInfo = myProfile.value.baseInfo;
  data.religion = myProfile.value.religion || "";
  data.smoking = myProfile.value.smoking || "";

  const updates: { [key: string]: any } = {};
  updates[`users/${user.value.uid}/profile/`] = data;
  updates[`users/${user.value.uid}/finder/location`] = data.baseInfo.mainInfo.location;
  updates[`users/${user.value.uid}/userInfo/registration/profile`] = true;
  try {
    await fbDB.ref().update(updates);
    await user.value.updateProfile({ photoURL: myProfile.value.baseInfo.profilePhoto });
    registrationProcess.value["profile"] = true;
    if (photoUploadSuccess) {
      return { result: 'success' };
    } else {
      return { result: 'fail', error: 'storage' };
    }
  } catch (err) {
    return { result: 'fail', error: 'database' }
  }
}
