import { modalController, isPlatform } from "@ionic/vue";

import Education from '@/components/profile/edit/modals/Education.vue';
import Work from '@/components/profile/edit/modals/Work.vue';
import Location from "@/components/profile/edit/modals/Location.vue";
import Hobbies from '@/components/profile/edit/modals/Hobbies.vue';
import Languages from '@/components/profile/edit/modals/Languages.vue';
import Smoking from '@/components/profile/edit/modals/Smoking.vue';
import Religion from '@/components/profile/edit/modals/Religion.vue';

export async function openEducationModal() {
  const modal = await modalController.create({
    component: Education,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openWorkModal() {
  const modal = await modalController.create({
    component: Work,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openLocationModal() {
  const modal = await modalController.create({
    component: Location,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}


export async function openHobbiesModal() {
  const modal = await modalController.create({
    component: Hobbies,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openLanguagesModal() {
  const modal = await modalController.create({
    component: Languages,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openSmokingModal() {
  const modal = await modalController.create({
    component: Smoking,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}

export async function openReligionModal() {
  const modal = await modalController.create({
    component: Religion,
    cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
    componentProps: {},
    swipeToClose: true,
  });
  return modal.present();
}