<template>
  <div v-if="myProfile == null">
    <p>No data.</p>
  </div>
  <div v-else style="max-width: 500px; margin: auto">
    <!-- TITLE PHOTO -->
    <ion-list>
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{$t("profile.titlePhoto")}}</ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <ion-card
          style="
            cursor: pointer;
            text-align: center;
            background-color: #777;
            margin: auto;
            height: 180px;
            width: 100%;
            margin-top: 15px;
            margin-bottom: 5px;
            border-radius: 5px 5px 45px 45px;
          "
        >
          <img
            @click="presentActionSheet('title')"
            v-if="myProfile.baseInfo.titlePhoto"
            :src="myProfile.baseInfo.titlePhoto"
          />
          <div
            v-else
            @click="choosePhoto('title')"
            style="
              cursor: pointer;
              text-align: center;
              height: 100%;
              width: 100%;
              border-radius: 5px 5px 45px 45px;
            "
          >
            <ion-icon
              size="large"
              :icon="imageOutline"
              style="height: 100%; color: ghostwhite"
            />
          </div>
        </ion-card>
      </ion-item>
    </ion-list>

    <!-- PROFILE PHOTO -->
    <ion-list>
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{$t("profile.profilePhoto")}}</ion-label>
      </ion-list-header>
      <div style="text-align: center">
        <ion-avatar
          style="
            cursor: pointer;
            text-align: center;
            background-color: #777;
            margin: auto;
            height: 150px;
            width: 150px;
            margin-top: 15px;
            margin-bottom: 5px;
          "
        >
          <img
            v-if="myProfile.baseInfo.profilePhoto"
            :src="myProfile.baseInfo.profilePhoto"
            @click="presentActionSheet('profile')"
          />
          <div
            v-else
            @click="choosePhoto('profile')"
            style="
              cursor: pointer;
              height: 100%;
              width: 100%;
              border-radius: 50%;
            "
          >
            <ion-icon
              size="large"
              :icon="personOutline"
              style="height: 100%; color: ghostwhite"
            />
          </div>
        </ion-avatar>
      </div>
    </ion-list>

    <!-- BIO -->
    <ion-list>
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{$t("profile.bio.bio")}}</ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <ion-textarea
          v-model="myProfile.baseInfo.bio"
          :placeholder="$t('profile.bio.placeholder')"
          maxlength="1000"
        ></ion-textarea>
      </ion-item>
    </ion-list>

    <!-- MAIN INFO -->
    <ion-list id="mainInfo" lines="none">
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{$t("profile.mainInfo")}}</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-icon slot="start" :icon="briefcaseOutline" style=""></ion-icon>
        <ion-label position="fixed" @click="openWorkModal()"> {{$t("profile.work.work")}}</ion-label>
        <ion-chip class='selectButton' color="dark" mode="ios" v-if="!jobToShow" outline @click="openWorkModal()">
          <ion-icon :icon="addOutline" />
          <ion-label>{{$t("buttons.choose")}}</ion-label>
        </ion-chip>
        <ion-chip v-if="jobToShow" @click="openWorkModal()">
          <ion-label>{{ $t("profile.work.options."+jobToShow.code)}}</ion-label>
        </ion-chip>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" :icon="schoolOutline" style=""></ion-icon>
        <ion-label position="fixed" @click="openEducationModal()">{{$t("profile.education.education")}}</ion-label>
        <ion-chip class='selectButton' color="dark" mode="ios" v-if="!educationToShow" outline @click="openEducationModal()">
          <ion-icon :icon="addOutline"></ion-icon>
          <ion-label>{{$t("buttons.choose")}}</ion-label>
        </ion-chip>
        <ion-chip v-if="educationToShow" @click="openEducationModal()">
          <ion-label>{{ $t("profile.education.options."+educationToShow.code) }}</ion-label>
        </ion-chip>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" :icon="locationOutline" style=""></ion-icon>
        <ion-label position="fixed" @click="openLocationModal()">{{$t("profile.location.location")}}</ion-label>
        <ion-chip class='selectButton' color="dark" mode="ios" v-if="!locationToShow" outline @click="openLocationModal()">
          <ion-icon :icon="addOutline" />
          <ion-label>{{$t("buttons.choose")}}</ion-label>
        </ion-chip>
        <ion-chip v-if="locationToShow" @click="openLocationModal()">
          <ion-label>{{  $t("profile.location.options."+locationToShow.code) }}</ion-label>
        </ion-chip>
      </ion-item>
      <p v-if="validationOn && (!jobToShow || !educationToShow || !locationToShow)" class="error">
        {{$t("validation.allRequired")}}
      </p>
    </ion-list>

    <ion-list>
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader" @click="openHobbiesModal()">{{$t("profile.hobbies.hobbies")}}</ion-label>
        <ion-chip color="dark" mode="ios" outline @click="openHobbiesModal()">
          <ion-icon :icon="addOutline"></ion-icon>
          <ion-label>{{$t("buttons.choose")}}</ion-label>
        </ion-chip>
      </ion-list-header>
      <p v-if="hobbiesToShow.length != 0 || (myProfile.customHobbies && myProfile.customHobbies.size != 0)">
        <ion-chip style="height: auto;" @click="openHobbiesModal()" v-for="(item, index) in myProfile.customHobbies" :key="index">
          <ion-label>{{ item }}</ion-label>
        </ion-chip>
        <ion-chip @click="openHobbiesModal()" v-for="(item, index) in hobbiesToShow" :key="index">
          <ion-label>{{  $t("profile.hobbies.options."+item.code) }}</ion-label>
        </ion-chip>
      </p>
    </ion-list>

    <ion-list>
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader" @click="openLanguagesModal()">{{$t("profile.languages.languages")}}</ion-label>
        <ion-chip color="dark" mode="ios" outline @click="openLanguagesModal()">
          <ion-icon :icon="addOutline"></ion-icon>
          <ion-label>{{$t("buttons.choose")}}</ion-label>
        </ion-chip>
      </ion-list-header>
      <p v-if="languagesToShow.length != 0">
        <ion-chip @click="openLanguagesModal()" v-for="(lang, index) in languagesToShow" :key="index">
          <ion-label>{{  $t("profile.languages.options."+lang.code) }}</ion-label>
        </ion-chip>
      </p>
    </ion-list>

    <ion-list lines="none">
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{$t("profile.additionalInfo")}}</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-icon slot="start" :icon="briefcaseOutline" style=""></ion-icon>
        <ion-label position="fixed" @click="openSmokingModal()"> {{ $t("profile.smoking.smoking") }} </ion-label>
        <ion-chip class='selectButton' color="dark" mode="ios" v-if="!smokingToShow" outline @click="openSmokingModal()">
          <ion-icon :icon="addOutline" />
          <ion-label>{{$t("buttons.choose")}}</ion-label>
        </ion-chip>
        <ion-chip v-if="smokingToShow" @click="openSmokingModal()">
          <ion-label>{{ $t("profile.smoking.options."+smokingToShow.code) }}</ion-label>
        </ion-chip>
      </ion-item>
      <ion-item>
        <ion-icon slot="start" :icon="schoolOutline" style=""></ion-icon>
        <ion-label position="fixed" @click="openReligionModal()">{{$t("profile.religion.religion")}}</ion-label>
        <ion-chip class='selectButton' color="dark" mode="ios" v-if="!religionToShow" outline @click="openReligionModal()">
          <ion-icon :icon="addOutline" />
          <ion-label>{{$t("buttons.choose")}}</ion-label>
        </ion-chip>
        <ion-chip v-if="religionToShow" @click="openReligionModal()">
          <ion-label>{{ $t("profile.religion.options."+religionToShow.code) }}</ion-label>
        </ion-chip>
      </ion-item>
    </ion-list>

    <ion-list lines="none">
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{ $t("profile.photos") }}</ion-label>
      </ion-list-header>
      <ion-grid>
        <ion-row>
          <ion-col
            v-for="(photoUrl, index) in myProfile.photos"
            :key="index"
            size="4"
            style="text-align: center; padding: 0px"
          >
            <ion-card style="height: 133px; margin: 2px; border-radius: 25px">
              <ion-img
                v-if="photoUrl"
                style="
                  cursor: pointer;
                  height: 100%;
                  object-fit: cover;
                  width: 100%;
                "
                :src="photoUrl"
                @click="presentActionSheet('gallery', index)"
              ></ion-img>
              <div
                v-else
                @click="choosePhoto('gallery', index)"
                style="
                  cursor: pointer;
                  border-radius: 25px;
                  width: 100%;
                  height: 100%;
                "
              >
                <ion-icon
                  size="large"
                  :icon="imageOutline"
                  style="height: 100%; color: grey"
                />
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-list>
    <input
      ref="myPhotoInput"
      type="file"
      accept="image/*"
      style="display: none"
      @change="previewMyPhoto"
    />
  </div>
</template>

<script lang="ts">
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonImg,
  IonIcon,
  IonChip,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonCard,
  actionSheetController,
} from "@ionic/vue";
import {
  informationCircleOutline,
  briefcaseOutline,
  locationOutline,
  homeOutline,
  schoolOutline,
  personOutline,
  cameraOutline,
  imageOutline,
  trash,
  imagesOutline,
  close,
  languageOutline,
  addOutline,
} from "ionicons/icons";
import {
  computed,
  ref,
} from "vue";

import {
  myProfile,
  photoFiles,
  validationOn,
} from "@/components/profile/";

import {
  openEducationModal,
  openWorkModal,
  openLocationModal,
  openLanguagesModal,
  openHobbiesModal,
  openSmokingModal,
  openReligionModal,
} from "@/components/profile/edit/helper";
import jsonData from "@/assets/criteriaData.json";
import { user } from "@/auth";
import { useI18n } from 'vue-i18n';
import router from '@/router';

export default {
  name: "ProfileEditForm",
  components: {
    IonLabel,
    IonIcon,
    // IonButton,
    // IonInput,
    IonListHeader,
    IonCard,
    IonItem,
    IonList,
    IonAvatar,
    IonTextarea,
    IonChip,
    IonImg,
    IonCol,
    IonRow,
    IonGrid,
  },
  setup() {
    if (user.value.displayName === "" || user.value.displayName == null) {
      router.replace("/registration/base");
    }

    const { t } = useI18n({ useScope: "global" });
    const myPhotoInput = ref();
    const selectedPhoto = ref<string>("");
    let myPhotosIndex: number | undefined = 1;

    const previewMyPhoto = (event: any) => {
      if (myProfile.value == null) {
        return;
      }
      if (event.target.files.length == 0) {
        return;
      }

      if (selectedPhoto.value == "profile") {
        myProfile.value.baseInfo.profilePhoto = URL.createObjectURL(
          event.target.files[0]
        );
        photoFiles.profilePhoto = event.target.files[0];
      } else if (selectedPhoto.value == "title") {
        myProfile.value.baseInfo.titlePhoto = URL.createObjectURL(
          event.target.files[0]
        );
        photoFiles.titlePhoto = event.target.files[0];
      } else if (selectedPhoto.value == "gallery") {
        if (myProfile.value.photos && myPhotosIndex != undefined) {
          myProfile.value.photos[myPhotosIndex] = URL.createObjectURL(
            event.target.files[0]
          );
          photoFiles.myPhotos[myPhotosIndex] = event.target.files[0];
        }
      }
    };
    const choosePhoto = (selector: string, index?: number) => {
      selectedPhoto.value = selector;
      myPhotosIndex = index;
      myPhotoInput.value.click();
    };
    const presentActionSheet = async (selector: string, index?: number) => {
      const actionSheet = await actionSheetController.create({
        header: t("profile.photo"),
        // cssClass: 'my-custom-class',
        buttons: [
          {
            text: t("buttons.delete"),
            role: "destructive",
            icon: trash,
            handler: async () => {
              if (myProfile.value) {
                if (selector == "title") {
                  myProfile.value.baseInfo.titlePhoto = "";
                  photoFiles.titlePhoto = null;
                } else if (selector == "profile") {
                  myProfile.value.baseInfo.profilePhoto = "";
                  photoFiles.profilePhoto = null;
                } else if (selector == "gallery") {
                  if (myProfile.value.photos && index != undefined) {
                    myProfile.value.photos[index] = "";
                    photoFiles.myPhotos[index] = null;
                  }
                }
              }
            },
          },
          {
            text: t("buttons.change"),
            icon: imagesOutline,
            handler: () => {
              if (selector == "title") {
                choosePhoto("title");
              } else if (selector == "profile") {
                choosePhoto("profile");
              } else if (selector == "gallery") {
                choosePhoto("gallery", index);
              }
            },
          },
          {
            text: t("buttons.cancel"),
            icon: close,
            role: "cancel",
          },
        ],
      });
      return actionSheet.present();
    };

    const listOfEducation = ref(jsonData.education);
    const educationToShow = computed(() => {
      for (const item of listOfEducation.value) {
        if (item.code == myProfile.value?.baseInfo.mainInfo.education) {
          return item;
        }
      }
      return null;
    });

    const listOfJobs = ref(jsonData.jobs);
    const jobToShow = computed(() => {
      for (const item of listOfJobs.value) {
        if (item.code == myProfile.value?.baseInfo.mainInfo.work) {
          return item;
        }
      }
      return null;
    });

    const listOfLocations = ref(jsonData.locations);
    const locationToShow = computed(() => {
      for (const item of listOfLocations.value) {
        if (item.code == myProfile.value?.baseInfo.mainInfo.location) {
          return item;
        }
      }
      return null;
    });

    const listOfHobbies = ref(jsonData.hobbies);
    const hobbiesToShow = computed(() => {
      return listOfHobbies.value.filter((item) => {
        return myProfile.value?.hobbies?.has(item.code);
      });
    });

    const listOfLanguages = ref(jsonData.languages);
    const languagesToShow = computed(() => {
      return listOfLanguages.value.filter((item) => {
        return myProfile.value?.languages?.has(item.code);
      });
    });

    const listOfSmoking = ref(jsonData.smoking);
    const smokingToShow = computed(() => {
      for (const item of listOfSmoking.value) {
        if (item.code == myProfile.value?.smoking) {
          return item;
        }
      }
      return null;
    });

    const listOfReligions = ref(jsonData.religions);
    const religionToShow = computed(() => {
      for (const item of listOfReligions.value) {
        if (item.code == myProfile.value?.religion) {
          return item;
        }
      }
      return null;
    });

    return {
      myProfile,
      educationToShow,
      jobToShow,
      languagesToShow,
      locationToShow,
      hobbiesToShow,
      religionToShow,
      smokingToShow,
      openEducationModal,
      openWorkModal,
      openLanguagesModal,
      openHobbiesModal,
      openLocationModal,
      openSmokingModal,
      openReligionModal,

      validationOn,
      presentActionSheet,
      // Photostaffs
      previewMyPhoto,
      selectedPhoto,
      myPhotoInput,
      choosePhoto,
      // properties
      // ...toRefs(myProfile),
      // FUNCTIONS

      // ICONS
      addOutline,
      informationCircleOutline,
      briefcaseOutline,
      homeOutline,
      locationOutline,
      schoolOutline,
      personOutline,
      cameraOutline,
      languageOutline,
      imageOutline,
    };
  },
};
</script>

<style scoped>
ion-chip.selectButton {
  font-weight: bold;
}

ion-list {
  border-radius: 25px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

ion-list p {
  padding: 0.5rem;
  margin: 0;
}

ion-list-header {
  padding: 0.5rem;
}

ion-chip {
  margin-left: 0;
}

ion-list-header ion-label.sectionHeader {
  margin: 0.5rem 0 0 0;
}


ion-item {
  --padding-start: 1rem;
}

.error {
  margin: 0;
  font-weight: light;
  font-size: smaller;
  color: rgb(228, 2, 2);
}
</style>