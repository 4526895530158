
import { defineComponent, ref, computed } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonRadio,
  IonRadioGroup,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  IonList,
  IonFab,
  IonIcon,
  IonFabButton,
  IonSearchbar,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline, arrowUpOutline } from "ionicons/icons";
import jsonData from "@/assets/criteriaData.json";
import { myProfile } from "@/components/profile/index";

export default defineComponent({
  name: "Work",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonIcon,
    IonLabel,
    IonList,
    IonFab,
    IonFabButton,
    IonTitle,
    IonSearchbar,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };
    const listOfJobs = ref(jsonData.jobs);

    const searchQuery = ref("");
    const lang = localStorage.language == "gb" ? "en" : "sk";
    const filteredJobsList = computed(() => {
      const filtered = listOfJobs.value.filter((item: any) => {
        return (
          item[lang].toLowerCase().indexOf(searchQuery.value.toLowerCase()) !==
          -1
        );
      });
      return filtered.sort((a: any, b: any) => {
        if (a[lang] < b[lang]) {
          return -1;
        }
        if (a[lang] > b[lang]) {
          return 1;
        }
        return 0;
      });
    });

    const scrollToTop = () => {
      document.querySelector("ion-card-header#header")?.scrollIntoView({behavior: "smooth"});
    };

    return {
      scrollToTop,
      myProfile,
      filteredJobsList,
      searchQuery,
      closeModal,
      arrowUpOutline,
      checkmarkOutline,
    };
  },
});
