<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{$t('buttons.done')}}
          </ion-button>
        </ion-buttons>
        <ion-title>
          <img src="/assets/logo.png" style="height:30px;"/>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card
        style="margin: 0px; border-radius: 0px; box-shadow: none"
      >
        <ion-card-header>
          <ion-card-subtitle>{{$t('profile.languages.languages')}}</ion-card-subtitle>
          <ion-card-title
            >{{$t('profile.languages.question')}}</ion-card-title
          >
        </ion-card-header>
        <ion-card-content>
          <ion-item v-for="(lang, index) in languagesList" :key="index">
            <ion-checkbox
              id="lang.code"
              color="primary"
              slot="start"
              :value="lang.code"
              @update:modelValue="lang.isChecked = $event"
              :modelValue="lang.isChecked"
              @ionChange="checkboxChanged($event)"
            ></ion-checkbox>
            <ion-label>{{ $t('profile.languages.options.'+ lang.code) }}</ion-label>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  IonCheckbox,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
// import { myCriteria } from "@/composables/partnerCriteria";
import {myProfile} from "@/components/profile/index";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Languages",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
    IonCheckbox,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };
    
    const languagesList = ref(jsonData.languages);
    
    languagesList.value.forEach((item) => {
      if (myProfile.value?.languages) {
        if (myProfile.value?.languages.has(item.code)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    /** Add or remove language to/from criteria directory. */
    const checkboxChanged = (event: any) => {
      // console.log(event);
      const langCode: string = event.detail.value;
      if (!myProfile.value?.languages) {
        if (myProfile.value != null) {
          myProfile.value["languages"] = new Set([langCode]);
        }
        return;
      }

      if (event.detail.checked == false) {
        myProfile.value.languages.delete(langCode);
      } else if (event.detail.checked == true) {
        myProfile.value.languages.add(langCode);
      }
    };

    return {
      checkboxChanged,
      languagesList,
      closeModal,
      checkmarkOutline,
    };
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
</style>