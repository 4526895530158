
import { defineComponent } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonRadio,
  IonRadioGroup,
  IonPage,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonTitle,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
import {myProfile} from "@/components/profile/index";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Smoking",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonRadio,
    IonRadioGroup,
    IonPage,
    IonList,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const smokingList = jsonData.smoking;

    return {
      smokingList,
      myProfile,
      closeModal,
      checkmarkOutline,
    };
  },
});
