<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{$t('buttons.done')}}
          </ion-button>
        </ion-buttons>
        <ion-title>
          <img src="/assets/logo.png" style="height:30px;"/>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card
        style="margin: 0px; border-radius: 0px; box-shadow: none"
      >
        <ion-card-header>
          <ion-card-subtitle>{{$t('profile.location.location')}}</ion-card-subtitle>
          <ion-card-title>{{$t('profile.location.question')}}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-radio-group
            v-model="myProfile.baseInfo.mainInfo.location"
          >
            <ion-item v-for="(item, index) in locationsList" :key="index">
              <ion-label>{{ $t('profile.location.options.'+item.code) }}</ion-label>
              <ion-radio
                slot="start"
                color="primary"
                :value="item.code"
              ></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonRadio,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonTitle,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline } from "ionicons/icons";
import { myProfile } from "@/components/profile/index";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Location",
  components: {
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonTitle,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const locationsList = ref(jsonData.locations);

    return {
      // checkboxChanged,
      myProfile,
      locationsList,
      closeModal,
      checkmarkOutline,
    };
  },
});
</script>

<style scoped>
ion-item {
  --background: inherit;
}
</style>