
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonImg,
  IonIcon,
  IonChip,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonCard,
  actionSheetController,
} from "@ionic/vue";
import {
  informationCircleOutline,
  briefcaseOutline,
  locationOutline,
  homeOutline,
  schoolOutline,
  personOutline,
  cameraOutline,
  imageOutline,
  trash,
  imagesOutline,
  close,
  languageOutline,
  addOutline,
} from "ionicons/icons";
import {
  computed,
  ref,
} from "vue";

import {
  myProfile,
  photoFiles,
  validationOn,
} from "@/components/profile/";

import {
  openEducationModal,
  openWorkModal,
  openLocationModal,
  openLanguagesModal,
  openHobbiesModal,
  openSmokingModal,
  openReligionModal,
} from "@/components/profile/edit/helper";
import jsonData from "@/assets/criteriaData.json";
import { user } from "@/auth";
import { useI18n } from 'vue-i18n';
import router from '@/router';

export default {
  name: "ProfileEditForm",
  components: {
    IonLabel,
    IonIcon,
    // IonButton,
    // IonInput,
    IonListHeader,
    IonCard,
    IonItem,
    IonList,
    IonAvatar,
    IonTextarea,
    IonChip,
    IonImg,
    IonCol,
    IonRow,
    IonGrid,
  },
  setup() {
    if (user.value.displayName === "" || user.value.displayName == null) {
      router.replace("/registration/base");
    }

    const { t } = useI18n({ useScope: "global" });
    const myPhotoInput = ref();
    const selectedPhoto = ref<string>("");
    let myPhotosIndex: number | undefined = 1;

    const previewMyPhoto = (event: any) => {
      if (myProfile.value == null) {
        return;
      }
      if (event.target.files.length == 0) {
        return;
      }

      if (selectedPhoto.value == "profile") {
        myProfile.value.baseInfo.profilePhoto = URL.createObjectURL(
          event.target.files[0]
        );
        photoFiles.profilePhoto = event.target.files[0];
      } else if (selectedPhoto.value == "title") {
        myProfile.value.baseInfo.titlePhoto = URL.createObjectURL(
          event.target.files[0]
        );
        photoFiles.titlePhoto = event.target.files[0];
      } else if (selectedPhoto.value == "gallery") {
        if (myProfile.value.photos && myPhotosIndex != undefined) {
          myProfile.value.photos[myPhotosIndex] = URL.createObjectURL(
            event.target.files[0]
          );
          photoFiles.myPhotos[myPhotosIndex] = event.target.files[0];
        }
      }
    };
    const choosePhoto = (selector: string, index?: number) => {
      selectedPhoto.value = selector;
      myPhotosIndex = index;
      myPhotoInput.value.click();
    };
    const presentActionSheet = async (selector: string, index?: number) => {
      const actionSheet = await actionSheetController.create({
        header: t("profile.photo"),
        // cssClass: 'my-custom-class',
        buttons: [
          {
            text: t("buttons.delete"),
            role: "destructive",
            icon: trash,
            handler: async () => {
              if (myProfile.value) {
                if (selector == "title") {
                  myProfile.value.baseInfo.titlePhoto = "";
                  photoFiles.titlePhoto = null;
                } else if (selector == "profile") {
                  myProfile.value.baseInfo.profilePhoto = "";
                  photoFiles.profilePhoto = null;
                } else if (selector == "gallery") {
                  if (myProfile.value.photos && index != undefined) {
                    myProfile.value.photos[index] = "";
                    photoFiles.myPhotos[index] = null;
                  }
                }
              }
            },
          },
          {
            text: t("buttons.change"),
            icon: imagesOutline,
            handler: () => {
              if (selector == "title") {
                choosePhoto("title");
              } else if (selector == "profile") {
                choosePhoto("profile");
              } else if (selector == "gallery") {
                choosePhoto("gallery", index);
              }
            },
          },
          {
            text: t("buttons.cancel"),
            icon: close,
            role: "cancel",
          },
        ],
      });
      return actionSheet.present();
    };

    const listOfEducation = ref(jsonData.education);
    const educationToShow = computed(() => {
      for (const item of listOfEducation.value) {
        if (item.code == myProfile.value?.baseInfo.mainInfo.education) {
          return item;
        }
      }
      return null;
    });

    const listOfJobs = ref(jsonData.jobs);
    const jobToShow = computed(() => {
      for (const item of listOfJobs.value) {
        if (item.code == myProfile.value?.baseInfo.mainInfo.work) {
          return item;
        }
      }
      return null;
    });

    const listOfLocations = ref(jsonData.locations);
    const locationToShow = computed(() => {
      for (const item of listOfLocations.value) {
        if (item.code == myProfile.value?.baseInfo.mainInfo.location) {
          return item;
        }
      }
      return null;
    });

    const listOfHobbies = ref(jsonData.hobbies);
    const hobbiesToShow = computed(() => {
      return listOfHobbies.value.filter((item) => {
        return myProfile.value?.hobbies?.has(item.code);
      });
    });

    const listOfLanguages = ref(jsonData.languages);
    const languagesToShow = computed(() => {
      return listOfLanguages.value.filter((item) => {
        return myProfile.value?.languages?.has(item.code);
      });
    });

    const listOfSmoking = ref(jsonData.smoking);
    const smokingToShow = computed(() => {
      for (const item of listOfSmoking.value) {
        if (item.code == myProfile.value?.smoking) {
          return item;
        }
      }
      return null;
    });

    const listOfReligions = ref(jsonData.religions);
    const religionToShow = computed(() => {
      for (const item of listOfReligions.value) {
        if (item.code == myProfile.value?.religion) {
          return item;
        }
      }
      return null;
    });

    return {
      myProfile,
      educationToShow,
      jobToShow,
      languagesToShow,
      locationToShow,
      hobbiesToShow,
      religionToShow,
      smokingToShow,
      openEducationModal,
      openWorkModal,
      openLanguagesModal,
      openHobbiesModal,
      openLocationModal,
      openSmokingModal,
      openReligionModal,

      validationOn,
      presentActionSheet,
      // Photostaffs
      previewMyPhoto,
      selectedPhoto,
      myPhotoInput,
      choosePhoto,
      // properties
      // ...toRefs(myProfile),
      // FUNCTIONS

      // ICONS
      addOutline,
      informationCircleOutline,
      briefcaseOutline,
      homeOutline,
      locationOutline,
      schoolOutline,
      personOutline,
      cameraOutline,
      languageOutline,
      imageOutline,
    };
  },
};
