<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar mode="ios">
        <ion-buttons slot="end">
          <ion-button @click="closeModal()" color="primary">
            {{ $t("buttons.done") }}
          </ion-button>
        </ion-buttons>
        <ion-title>
          <img src="/assets/logo.png" style="height: 30px" />
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card style="margin: 0px; border-radius: 0px; box-shadow: none">
        <ion-card-header>
          <ion-card-subtitle>{{
            $t("profile.hobbies.hobbies")
          }}</ion-card-subtitle>
          <ion-card-title>{{ $t("profile.hobbies.question") }}</ion-card-title>
        </ion-card-header>
        <ion-card-content style="text-align: left">
          <div style="margin-bottom: 1rem">
            <ion-item
              lines="none"
              style="
                border: 1px solid #ddd;
                border-radius: 25px;
                --inner-padding-end: 0px;
              "
            >
              <ion-input
                v-model="myHobbie"
                inputmode="text"
                maxlength="100"
                :placeholder="$t('profile.hobbies.inputPlaceholder')"
              ></ion-input>
              <ion-button
                @click="addCustomHobbie()"
                shape="round"
                style="height: 2.2rem; margin: 0 6px; width: 5.3rem"
                color="primary"
                slot="end"
              >
                <ion-icon slot="start" :icon="addOutline"></ion-icon>
                {{ $t("buttons.add") }}
              </ion-button>
            </ion-item>
          </div>
          <div
            v-if="myProfile.customHobbies && myProfile.customHobbies.size != 0"
          >
            <ion-chip
              v-for="(item, index) in myProfile.customHobbies"
              :key="index"
              color="primary"
            >
              <ion-label>{{ item }}</ion-label>
              <ion-icon
                @click="removeCustomHobbie(item)"
                :icon="closeOutline"
              ></ion-icon>
            </ion-chip>
            <hr style="background: #ddd; margin: 1rem 0px" />
          </div>
          <div>
            <ion-chip
              v-for="(item, index) in filteredHobbiesList"
              :key="index"
              :color="item.isChecked ? 'primary' : 'dark'"
              @click="hobbyToggle(item)"
            >
              <ion-label>{{
                $t("profile.hobbies.options." + item.code)
              }}</ion-label>
            </ion-chip>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonButton,
  IonItem,
  IonIcon,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonLabel,
  IonTitle,
  IonChip,
  IonInput,
  modalController,
} from "@ionic/vue";
import { checkmarkOutline, addOutline, closeOutline } from "ionicons/icons";
import { myProfile } from "@/components/profile/index";
import jsonData from "@/assets/criteriaData.json";

export default defineComponent({
  name: "Hobbies",
  components: {
    IonButton,
    IonItem,
    IonIcon,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonLabel,
    IonTitle,
    IonChip,
    IonInput,
  },
  props: {},
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const myHobbie = ref<string>("");
    const addCustomHobbie = () => {
      if (myHobbie.value.trim() == "") {
        return;
      }

      const newHobbie = myHobbie.value?.trim();

      if (!myProfile.value?.customHobbies) {
        if (myProfile.value != null) {
          myProfile.value["customHobbies"] = new Set([newHobbie]);
        }
        return;
      }

      myProfile.value?.customHobbies?.add(newHobbie);
      myHobbie.value = "";
    };

    const removeCustomHobbie = (item: string) => {
      if (myProfile.value?.customHobbies?.has(item)) {
        myProfile.value?.customHobbies?.delete(item);
      }
    };

    const filteredHobbiesList = ref(jsonData.hobbies);
    filteredHobbiesList.value.forEach((item) => {
      if (myProfile.value?.hobbies) {
        if (myProfile.value?.hobbies.has(item.code)) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      }
    });

    const hobbyToggle = (selectedHobby: any) => {
      if (!myProfile.value?.hobbies) {
        // myProfile.value = {...myProfile.value, hobbies:new Set()};
        if (myProfile.value != null) {
          selectedHobby.isChecked = true;
          myProfile.value["hobbies"] = new Set([selectedHobby.code]);
        }
        return;
      }

      if (myProfile.value?.hobbies.has(selectedHobby.code)) {
        selectedHobby.isChecked = false;
        myProfile.value?.hobbies.delete(selectedHobby.code);
      } else {
        selectedHobby.isChecked = true;
        myProfile.value?.hobbies.add(selectedHobby.code);
      }
    };

    return {
      addCustomHobbie,
      removeCustomHobbie,
      myHobbie,
      addOutline,
      closeOutline,
      hobbyToggle,
      filteredHobbiesList,
      myProfile,
      closeModal,
      checkmarkOutline,
    };
  },
});
</script>