<template>
  <div style="max-width: 500px; margin: auto">
    <ion-list>
      <ion-list-header lines="full">
        <ion-label
          ><ion-skeleton-text animated style="width: 40%"></ion-skeleton-text
        ></ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <ion-card
          style="
            text-align: center;
            background-color: #333;
            margin: auto;
            height: 180px;
            width: 100%;
            margin-top: 15px;
            border-radius: 5px 5px 45px 45px;
          "
        >
        </ion-card>
      </ion-item>
    </ion-list>

    <!-- PROFILE PHOTO -->
    <ion-list>
      <ion-list-header lines="full">
        <ion-label
          ><ion-skeleton-text animated style="width: 40%"></ion-skeleton-text
        ></ion-label>
      </ion-list-header>
      <div style="text-align: center">
        <ion-avatar
          style="
            text-align: center;
            margin: auto;
            height: 150px;
            width: 150px;
            margin-top: 15px;
          "
        >
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
      </div>
    </ion-list>

    <!-- BIO -->
    <ion-list>
      <ion-list-header lines="full">
        <ion-label
          ><ion-skeleton-text animated style="width: 40%"></ion-skeleton-text
        ></ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </ion-item>
    </ion-list>
  </div>
</template>

<script>
import {
  IonCard,
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonAvatar,
    IonCard,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonSkeletonText,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
ion-list {
  border-radius: 25px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

ion-list-header ion-label ion-skeleton-text {
  line-height: 28px;
}

ion-skeleton-text {
  border-radius: 20px;
}

ion-avatar ion-skeleton-text {
  border-radius: 50%;
}
</style>
